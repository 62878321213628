// IMGDumper.nl Theme
$light: #eeeeee;
$success: #79c24f;
$danger: #c25e4f;
$info: #4fb2c2;
$theme-colors: (
    "light": $light,
    "success": $success,
    "danger": $danger,
    "info": $info,
);

.upload-wrapper {
    box-sizing: border-box;
}

.form-signin {
  width: 100%;
  max-width: 370px;
  padding: 15px;
  margin: auto;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}


// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
